<template>
  <v-container class="mt-3 mx-3 px-0 white fill-height v-align-start font-inter card relative">
    <v-row justify="center">
      <v-col cols="12" sm="12" class="flex justify-between pl-6 pr-10">
        <div class="back-btn">
          <router-link :to="`/tests/cases`">
            <v-icon>mdi-chevron-left</v-icon> {{ $t('backToTestCases') }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="block main-area">
        <h2>{{ $t('exportToSpreadsheet') }}</h2>
        <div class="mt-8">
          <h5>{{ $t('format') }}</h5>
          <v-radio-group v-model="selectedFormat" @change="handlChange">
            <v-radio label=".csv" value="csv" key="1" />
            <v-radio label=".xlsx" value="xlsx" key="2" />
          </v-radio-group>
          <h5>{{ $t('testSuites') }}</h5>
          <v-radio-group v-model="selectedSuiteMethod" @change="handlChange">
            <v-radio label="Export all test suites" value="all" key="1" />
            <v-radio label="Export the following test suites only" value="following" key="2" />
          </v-radio-group>
          <v-select placeholder="Test suite" v-if="selectedSuiteMethod=='following'" v-model="selectedSuites" filled dense height="40" class="round-8 ml-8" multiple :items="testSuites" item-text="text" item-value="value">
            <template v-slot:prepend-item>
              <v-list-item
              ripple
              @mousedown.prevent
              @click="toggleSuite"
              >
              <v-list-item-action>
                <v-icon :color="selectedSuites.length > 0 ? 'indigo darken-4' : ''">
                  {{ suiteIcon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('selectAll') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <v-select placeholder="Test suite" v-else disabled filled dense height="40" class="round-8 ml-8" ></v-select>
          <h5 class="mt-6"> {{ $t('fields') }} </h5>
          <v-select placeholder="Chose fields" v-model="selectedFields" filled dense height="40" class="round-8" multiple :items="fields" item-text="text" item-value="value">
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggleField"
              >
                <v-list-item-action>
                  <v-icon :color="selectedFields.length > 0 ? 'indigo darken-4' : ''">
                    {{ fieldIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="absolute bottom-0 flex justify-end">
        <v-btn class="text-capitalize text-white px-6 py-2 round-6 mr-6 mb-4" color="#0C2FF3">{{ $t('export') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data(){
    return {
      selectedFormat: "csv",
      selectedField: "",
      selectedSuiteMethod: "following",
      selectedSuites: [],
      selectedFields: [],
      testSuites: [
        { value: "Text", text: 'Text'},
        { value: "Html", text: 'Html'},
        { value: "JavaScript", text: 'JavaScript'},
        { value: "Python", text: 'Python' },
      ],
      fields: [
        { value: "Text", text: 'Text'},
        { value: "Html", text: 'Html'},
        { value: "JavaScript", text: 'JavaScript'},
        { value: "Python", text: 'Python' },
        { value: "c", text: 'C++'},
        { value: "React", text: 'React'},
        { value: "Vue", text: 'Vue'},
        { value: "Angular", text: 'Angular' },
      ]
    }
  },
  methods: {
    handlChange() {
      console.log(this.selectedFormat);
      console.log(this.selectedSuiteMethod)
    },
    toggleField () {
      this.$nextTick(() => {
        if (this.selectAllFields) {
          this.selectedFields = []
        } else {
          this.selectedFields = this.fields.slice()
        }
      })
    },
    toggleSuite() {
      this.$nextTick(() => {
        if (this.selectAllSuites) {
          this.selectedSuites = []
        } else {
          this.selectedSuites = this.fields.slice()
        }
      })
    },
  },
  computed: {
    selectAllFields () {
      return this.selectedFields.length === this.fields.length
    },
    selectSomeFields () {
      return this.selectedFields.length > 0 && !this.selectAllFields
    },
    fieldIcon () {
      if (this.selectAllFields) return 'mdi-close-box'
      if (this.selectSomeFields) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectAllSuites () {
      return this.selectedSuites.length === this.fields.length
    },
    selectSomeSuites () {
      return this.selectedSuites.length > 0 && !this.selectAllFields
    },
    suiteIcon () {
      if (this.selectAllSuites) return 'mdi-close-box'
      if (this.selectSomeSuites) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
}
</script>
<style>
h2, h5{
  text-align: left;
}
h2{
  font-size: 24px !important;
  font-weight: 700;
  color: #18181A;
}
h5{
  font-size: 14px !important;
  color: #0C111D;
  font-weight: 500;
}
button {
  font-size: 14px;
}
.v-align-start {
  align-items: flex-start !important;
}
.font-inter{
  font-family: Inter;
}
.card{
  border-radius: 8px;
}
.block{
  display: block;
}
.main-area{
  max-width: 438px;
  margin-left: auto;
  margin-right: auto;
}
.round-8{
  border-radius: 8px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.bottom-0{
  bottom: 0;
}
.justify-end{
  justify-content: flex-end;
}
.justify-between{
  justify-content: space-between;
}
.text-white{
  color: white !important;
}
.round-6{
  border-radius: 6px;
}
.flex{
  display: flex;
}
.w-50{
  width: 50%;
}
.aling-left .v-list{
  text-align: left !important;
}
</style>